@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    background-color:lightgray;
    background-image: url("/public/image/bg.jpg");
}

.imgButton{
    width: 60%;
    max-width: 240px;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
}